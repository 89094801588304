import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import SEO from "../components/SEO";
import BeatLoader from "react-spinners/BeatLoader";
import Cookies from "js-cookie";

const IndexPage = ({ location }) => {
  const lang = "en";
  const pageSlug = "index";
  const subTitle = "Traditional Japanese House in Nagano";
  const [urlLang, setUrlLang] = useState("jp");

  // Function for redirect
  const getRedirectLanguage = () => {
    const selectLang = Cookies.get("selectLang");

    if (selectLang === "ja") {
      setUrlLang("jp");
    } else if (selectLang === "en") {
      setUrlLang("en");
    } else {
      getBrowserLanguage();
    }
  };

  const getBrowserLanguage = () => {
    if (typeof navigator === `undefined`) {
      setUrlLang("jp");
    }

    const browserLang =
      navigator && navigator.language && navigator.language.split("-")[0];

    switch (browserLang) {
      case "ja":
        setUrlLang("jp");
        return;
      case "en":
        setUrlLang("en");
        return;
      default:
        setUrlLang("en");
    }
  };

  useEffect(() => {
    getRedirectLanguage();
    navigate(`/${urlLang}/`);
  });

  const loading = useState(true);
  const loadingStyle = {
    display: "block",
    textAlign: "center",
    padding: "2rem 0",
    animationDuration: "0.5s",
  };

  return (
    <>
      <SEO
        lang={lang}
        pageSlug={pageSlug}
        location={location}
        subTitle={subTitle}
      />
      <div className="fadein" style={loadingStyle}>
        <BeatLoader
          size={10}
          style={{ margin: "auto" }}
          color={"#313131"}
          margin={5}
          loading={loading}
        />
      </div>
    </>
  );
};

export default IndexPage;
